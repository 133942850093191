@font-face {font-family: "Circular Std Book"; src: url("//db.onlinewebfonts.com/t/860c3ec7bbc5da3e97233ccecafe512e.eot"); src: url("//db.onlinewebfonts.com/t/860c3ec7bbc5da3e97233ccecafe512e.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/860c3ec7bbc5da3e97233ccecafe512e.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/860c3ec7bbc5da3e97233ccecafe512e.woff") format("woff"), url("//db.onlinewebfonts.com/t/860c3ec7bbc5da3e97233ccecafe512e.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/860c3ec7bbc5da3e97233ccecafe512e.svg#Circular Std Book") format("svg"); }

body {
    font-family: "Circular Std Book" !important;
}

.bg-body {
    background: #1d2227;
    background-image: url(../img/body-update.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
}

.bg-black-body {
    background: #1d2227;
}

.mark-aws {
    border-bottom: 3px solid #f6be6b;
    margin-left: 10px;
}

.p-10 {
    padding: 10%
}

.content-container {
    border-radius: 25px;
}

.cl-dark-grey {
    color: #a0a5ba
}

.cl-white {
    color: white;
}

.cl-black {
    color: black;
}

.bg-yellow {
    background-color: #f6be6b;
}

.bg-black {
    background-color: #13171a;
}

.bg-green {
    background-color: #5cc07b;
}

.bg-red {
    background-color: #ee6a5d;
}

.cl-gold-yellow {
    color: #f6be6b;
}

.video-title-content {
    font-size: 2.6em;
    text-align: left;
}

.video-description-content {
    font-size: 1.6em;
    text-align: left;
}

.video-button {
    text-align: left;
}

.stack-button {
    text-align: center;
}

.pad-3 {
    padding: 1.5em;
}

.pad-5 {
    padding: 3em;
}

.video-button-content {
    font-size: 32px;
}

.w-15 {
    width: 10%
}

.card-title {
    display: flex;
    align-items: center;
    text-align: left;
}

.card-text-size {
    /* width: 15%; */
    font-size: 1em;
    text-align: left;
    line-height: 30px;
}

.card-title span {
    margin-left: .7em;
    font-size: 1.6em;
    font-weight: bold;
}

.section-title {
    font-size: 1.6em;
    font-weight: bold;
    line-height: 55px;
    color: white;
}

.google-section-title {
    font-size: 1.5em;
    font-weight: bold;
    line-height: 35px;
    color: white;
}

.section-subtitle {
    font-size: 1em;
    line-height: 35px;
    color: #a0a5ba
}

.google-section-subtitle {
    font-size: 1em;
    line-height: 30px;
    color: #a0a5ba
}

.card-section-container {
    height: fit-content;
    padding: 1em;
}

.card-section {
    border: 2px dashed #5ec27d;
    border-radius: 5px;
    padding: 1.1em;
}

.bg-dark-black  {
    background-color: #13171a;
}

.bg-light-black {
    background-color: #252c32;
}

.bdRadius-top {
    border-top-right-radius: 70px;
    border-top-left-radius: 70px;
    margin-top: -40px;
}

.bdRadius-bottom {
    border-bottom-right-radius: 70px;
    border-bottom-left-radius: 70px;
}

.subsection-title {
    color: white;
    font-size: 1.5em;
    font-weight: bold;
}

.subsection-description {
    color: #a0a5ba;
    font-size: 1em;
}

.sec-stack {
    padding: 3em
}

.sec-img {
    padding: 2em;
}

.justify-center {
    justify-content: center;
}

.subsec-padding {
    padding: 1.5em
}

.google-section {
    text-align: left;
}

.google-section span {
    padding: 5px 12px;
    border-radius: 3px;
    display: inline-block;
    margin: 7px;
}

.justify-between {
    justify-content: space-between;
    align-items: center;
    display: flex;
}


.copyright {
    color: #a0a5ba;
    font-size: 1em;
}

.footer {
    text-align: left;
}

.footer-input-box {
    background-color: white;
    padding: 5px 9px;
    border-radius: 4px;
}

iframe {
    width: 100%;
}

.footer-input-box img {
    width: 32px;
    margin-left: 10px;
}

.btn-submit {
    text-align: center;
    background-color: white;
    outline: none;
    border: none;
}

.btn-submit.visit {
    border: none;
}

.terminal-icons span {
    display: inline-block;
    color: #a0a5ba
}

.terminal-icons {
    text-align: left;
}

.terminal-icons span {
    width: 16px;
}

.terminal-icons span img {
    width: 100%;
}

.terminal-icons span:not(:first-child) {
    margin-left: 8px;
}

.social-icons span {
    display: inline-block;
    color: #a0a5ba
}

.social-icons span:not(:first-child) {
    margin-left: 30px;
}

.footer-input {
    outline: none;
    border: 1px solid white;
    width: 100%
}

@media screen and (max-width: 1200px) {

    .video-title-content {
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
    }

    .margin-responsive {
        margin-top: 50px;
    }
    
    .video-description-content {
        font-size: 20px;
        text-align: center;
    }
    
    .video-button {
        text-align: center;
    }

    .video-button-content {
        font-size: 22px;
        text-align: center;
    }

}

@media screen and (max-width: 768px) {
    .p-10 {
        padding: unset
    }

    .video-title {
        margin-top: 50px;
    }

    .video-title-content {
        font-size: 35px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
    }
    
    .video-description-content {
        font-size: 20px;
        text-align: center;
    }
    
    .video-button {
        text-align: center;
    }

    .video-button-content {
        font-size: 22px;
        text-align: center;
    }

    .sec-stack {
        padding-top: 50px;
    }

    .footer-menu > div > div:not(:first-child) {
        margin-top: 30px;
    }
}

@media screen and (max-width: 414px) {

    .w-15 {
        width: 10%
    }

    .footer-menu > .row > div:not(:first-child) {
        margin-top: 40px;
    }

    .footer {
        text-align: center;
    }

    .trust-sheet .sec-img:last-child {
        margin-top: 40px;
    }

    .sec-img {
        padding: 10px
    }

    .section-left:last-child {
        margin-top: 50px;
    }

    .bdRadius-top, .bdRadius-bottom {
        border-radius: unset;
    }

    .sec-stack {
        padding-left: 10px;
        padding-right: 10px;
    }

    .justify-between {
        display: block;
    }

    .footer-input {
        width: 80%;
    }

    .justify-between > div:last-child  {
        margin-top: 60px;
    }
    
    .pad-5, .pad-3 {
        padding: unset ;
    }
}